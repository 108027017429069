


#open {
    background-image: url('../../media/diagonal.svg'), url('../../media/other_diagonal.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
};

details > summary::-webkit-details-marker {
    display: none;
}

#open::-webkit-details-marker {
    display: none;
}

#closed::-webkit-details-marker {
    display: none;
}