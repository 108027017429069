#schedule-container {
    display: flex;
    flex-direction: column;
    /* border-radius: 0rem 0.625rem 0.625rem 0.625rem; */
    padding: 3.75rem 2.31rem 5.19rem 2.19rem;
    gap: 2.56rem;
}

#tab1, #tab2 {
    cursor: pointer;
}