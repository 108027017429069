.team-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.313rem;
    margin-bottom: 2.875rem;
    text-align: center;
}

.member-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 13.438rem;
    width: 16.125rem;
}

.member-image {
    border-radius: 1.25rem;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.member-role {
    font-size: 1.25rem;
    padding-top: 0.313rem;
    padding-bottom: 0.313rem;
}

.member-name {
    padding-top: 0.313rem;
    padding-bottom: 0.313rem;
}

@media screen and (max-width: 590px) {
    .team-member {
        gap: 0.313rem;
        margin-bottom: 2rem;
    }
    
    .member-image-container {
        height: 8.75rem;
        width: 8.75rem;
    }

    .member-role {
        font-size: 0.75rem;
    }

    .member-name {
        font-size: 0.75rem;
    }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.fade-in {
  animation: fadeIn 1s linear;
}

