#container {
    padding-left: 8.75rem;
    padding-right: 8.75rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.188rem;
}

#team-title {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    font-size: 2.5rem;
}

#team-intro {
    margin-bottom: 4.313rem;
    padding-top: 0.563rem;
    padding-bottom: 0.563rem;
}

#members-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 1rem;
}

#teams-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 3.063rem;
}

#teams-container > button {
    color: #000;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

#selected-team {
    font-weight: 700 !important;
}

@media screen and (max-width: 820px) {
    #container {
        padding-left: 1.875rem;
        padding-right: 1.875rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 1.625rem;
    }
}

@media screen and (max-width: 440px) {
    #team-title {
        font-size: 1.375rem;
    }

    #team-intro {
        font-size: 0.75rem;
    }

    #team-intro {
        margin-bottom: 2.5rem;
    }

    #teams-container {
        gap: 1rem;
        margin-bottom: 1.438rem;
    }

 
    #teams-container > button {
        font-size: 0.75rem;
    }
}

