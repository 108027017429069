
#footer {
    background-image: url('../../media/small-footer.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    margin-top: auto;
    
}

@media (min-width: 440px) {

    #footer {
        background-size: cover;
        background-image: url('../../media/m-footer.svg');
    }
}

@media (min-width: 820px) {

    #footer {
        background-size: cover;
        background-image: url('../../media/larger-footer.svg');
    }
}

#insta {
    background-image: url('../../media/instagram.svg');
    background-size: cover; 
     
}

#linkedin {
    background-image: url('../../media/linkedin.svg');
    background-size: cover; 

}

#root {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;


}
