.schedule-item {
    background-color: white;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    padding: 1.13rem 2.06rem 1.13rem 2.06rem;
    position: relative;
    align-items: center;
}

.schedule-time {
    color: #6E6E6E;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 160%;
}

.schedule-title {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 160%;
}

.schedule-description, .speaker-name {
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 160%;
}

.speaker-container {
    display: inline-flex;
    padding: 0.25rem 0.9375rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.3125rem;
}

.speakers-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    gap: 1.37rem;
}

.vertical-line {
    height: calc(100% - 2.26rem);
    width: 2px;
    background-color: black;
    border: 1px solid black;
    position: absolute;
    top: 1.13rem;
    bottom: 1.13rem;
    left: 26.25rem;
}

.left {
    display: flex;
    flex-direction: column;
}

.right {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 27.87rem;
    justify-content: center;
    height: calc(100% - 2.26rem);
}

@media screen and (max-width: 1300px) {
    .speakers-container {
        flex-direction: column;
        gap: 0.5rem;
        width: min-content;
        flex-wrap: nowrap; 
        justify-content: center; 
    }

    .speaker-container {
        width: max-content;    
    }

    .schedule-description {
        font-size: medium;
     
    }

    .right {
        left: 22.5rem;
        flex-direction: row;
    }
}

@media screen and (max-width: 1350px) {
    .vertical-line {
        left: 21.5rem;
    }

    .right {
        left: 22.5rem;
        gap: 0.5rem;
    }

    .schedule-item {
        justify-content: left;
        gap: 1rem;
    }
}

@media screen and (max-width: 1160px) {
    .vertical-line {
        left: 13.5rem;
    }

    .right {
        left: 14.5rem;
    }

    
}

@media screen and (max-width: 700px) {
    .vertical-line {
        left: 12rem;
    }

    .right {
        left: 12.5rem;
    }
}

@media screen and (max-width: 510px) {
    .vertical-line {
        left: 10.5rem;
    }

    .right {
        left: 11rem;
    }
}

@media screen and (max-width: 470px) {
    .vertical-line {
        left: 8.8rem;
    }

    .right {
        left: 9.3rem;
    }
}

@media screen and (max-width: 900px) {
    .schedule-time, .schedule-title, .schedule-description, .speaker-name {
        font-size: 0.75rem !important
    }
}

@media screen and (max-width: 585px) {
    .schedule-time, .schedule-title, .schedule-description, .speaker-name {
        font-size: 0.625rem !important;
    }
}

@media screen and (max-width: 1160px) {
    .schedule-time, .schedule-title, .schedule-description, .speaker-name {
        font-size: 0.85rem;
    }
}

@media screen and (max-width: 470px) {
    .schedule-time, .schedule-title, .schedule-description, .speaker-name {
        font-size: 0.5rem !important;
    }
}

@media screen and (max-width: 420px) {
    .schedule-item {
        padding-left: 1rem;
    }
}

@media screen and (max-width: 420px) {
    .vertical-line {
        left: 7.5rem;
    }

    .right {
        left: 8.3rem;
    }
}

@media screen and (max-width: 390px) {
    .schedule-time, .schedule-title, .schedule-description, .speaker-name {
        font-size: 7px !important;
    }
}

@media screen and (max-width: 380px) {
    .vertical-line {
        left: 6.8rem;
    }

    .right {
        left: 7.5rem;
    }

    .speaker-container{
        padding: 2px;
    }
}