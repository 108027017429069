#schedule-page-container {
    margin-bottom: 2.06rem;
}

@media screen and (max-width: 820px) {
    #schedule-page-container {
        margin-left: 3rem;
        margin-right: 3rem;
    }
}

@media screen and (max-width: 440px) {
    #schedule-page-container {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
}
