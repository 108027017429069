

#grid {

   grid-template-columns: repeat(auto-fill, minmax(9.75rem, 1fr));
}

@media (min-width: 768px) {
    #grid {
        grid-template-columns: repeat(auto-fill, minmax(13.5rem, 1fr));
    }

}
