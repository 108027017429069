

@keyframes bounce {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}

#bounce-animation {
    animation: bounce 0.5s ease;
}

#committee-card {
    background-position: 50% 35%;
}