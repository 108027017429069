

#register {
    color: white;
    background-color: #DF4440;
    border-radius: 1rem;
    padding: 1.25rem 1rem;
    width: 180px;
    text-align: center;
    border-bottom: 4.5rem;
    font-size: 20px;
}

/* #register:hover {
    font-weight: bolder;
    background: #B52522;
} */
#hero-section {
    background-image: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url('../../media/hero.jpg');
    background-size: cover; /* Cover the entire viewport */
    background-position: center top; /* Center the background image */
    /* opacity: 0.7; */
    /* min-height: 25rem; */
    min-height: 24.5rem;
}

/* same as tailwind's md, this applies to those w geq width */
@media (min-width: 1094px) {
    #hero-section #register {
        width: 300px;
        font-size: 2.5rem;
    }
    #hero-section {
        height: 557px;
    }
}

@media only screen and (max-width: 390px) {
    #red_top {
        background-image: url('../../media/st-red.svg');
        background-size: cover;
        height: 123px;
        width: 100%;
    }

    #red_bottom {
        background-image: url('../../media/sb-red.svg');
        background-size: cover;
        height: 199px;
        width: 100%;
    }
}

@media only screen and (min-width: 390px) and (max-width: 800px) {
    #red_top {
        background-image: url('../../media/mt-red.svg');
        background-size: cover;
        height: 127px;
        width: 100%;
    }

    #red_bottom {
        background-image: url('../../media/mb-red.svg');
        background-size: cover;
        height: 155px;
        width: 100%;
    }
}

@media only screen and (min-width: 800px) {
    #red_top {
        background-image: url('../../media/lt-red.svg');
        background-size: cover;
        height: 127px;
        width: 100%;
    }

    #red_bottom {
        background-image: url('../../media/lb-red-lp.svg');
        background-size: cover;
        height: 120px;
        width: 100%;
    }
}

@media only screen and (max-width: 740px) {
    .dot {
        width: 0.3rem;
        height: 0.3rem;
        background-color: #D9D9D9;
        border-radius: 9999px;
        margin: 0.15rem;
    }
    
    .dot.active {
        background-color: #797979;
    }
}

@media only screen and (min-width: 740px) {
    .dot {
        width: 0.5625rem;
        height: 0.5625rem;
        background-color: #D9D9D9;
        border-radius: 9999px;
        margin: 0.28125rem;
    }

    .dot.active {
        background-color: #797979;
    }
}




/* 
.react-slideshow-wrapper.slide {
    border-radius: 1.25rem;
} */